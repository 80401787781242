import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/auth';
// Above the createApp() line
import { defineCustomElements } from '@ionic/pwa-elements/loader';
// @ts-ignore
import Ripple from 'lightvue/ripple';

// Call the element loader after the platform has been bootstrapped
Vue.config.productionTip = false;

Vue.prototype.$axios = axios;

Vue.directive('ripple', Ripple);
new Vue({
  router,
  store,
  render: h => h(App),

  created() {
    var firebaseConfig = {
      apiKey: 'AIzaSyDWyrymK1Faujn9ILv3Yz6adBMbl8Xq150',
      authDomain: 'rurban-s.firebaseapp.com',
      projectId: 'rurban-s',
      storageBucket: 'rurban-s.appspot.com',
      messagingSenderId: '299985687061',
      appId: '1:299985687061:web:c1c69a3adf0a385cd1b41c',
      measurementId: 'G-QM9NG4G5Q1',
    };
    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);
    // firebase.analytics();
  },
}).$mount('#app');
defineCustomElements(window);
