import Vue from 'vue';
import Vuex from 'vuex';
import { defaultFormData } from './default.js';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    FormData: defaultFormData,
    // Photos: [],
  },
  mutations: {
    setFormData: (state, FormData) => {
      state.FormData = FormData;
    },
  },
  // mutations: {
  //   setPhotos: (state, Photos) => {
  //     state.Photos = Photos;
  //   },
  // },
  // actions: {
  //   setPhotos: ({ commit }, Photos) => {
  //     commit('Photos', Photos);
  //   },
  // },
  actions: {
    setFormData: ({ commit }, FormData) => {
      commit('setFormData', FormData);
    },
  },
  // getters: {
  //   getFormData: state => {
  //     state.FormData;
  //   },
  // },
  modules: {},
});
