<template>
  <div id="app">
    <Navbar />
    <div class="page-container">
      <router-view />
    </div>
  </div>
</template>

<script>
import Navbar from '@main/components/Navbar.vue';
export default {
  components: {
    Navbar,
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import '~light-icons/dist/light-icon.css';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}
body {
  font-family: 'Roboto', sans-serif;
  background-color: #f5f8fa;
}
.page-container {
  padding-top: 36px;
}
.lv-ink {
  background-color: #63767e !important;
}
</style>
