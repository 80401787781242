const defaultFormData = {
  form_id: 'form_IIwJdjQW1LQMfbvIqEKM',
  form_data: {
    form_type: 'form',
    form_slug: 'rurban-survey',
    accepting_responses: true,
    end_date: '',
    req_end_date: false,
    total_responses: 27,
    security: 'private',
    author_id: 'usr_LQphGTKnb7OnDawmo7GTp20M7xq1',
    _date_added: {
      seconds: 1617167540,
      nanoseconds: 84000000,
    },
    _date_updated: {
      seconds: 1617981518,
      nanoseconds: 487000000,
    },
    _id: 'form_IIwJdjQW1LQMfbvIqEKM',
    deleted: false,
    max_responses: 1000,
    project_name: 'Household Survey',
    status: 'published',
    schema: {
      settings: {
        section_heading_visibility: true,
        form_description: 'Shyam Prasad Mukherjee Rurban Mission',
        form_heading: 'Household QUESTIONNAIRE ',
        form_header: true,
        meta_info: {
          page_title: '',
          page_description: '',
          thumbnail_url: '',
        },
        multiple_responses: false,
        styles: {
          playground_padding_left: 64,
          playground_background: '#f5f8fa',
          button_width: '',
          form_theme_color: 'Teal',
          rounded_input: false,
          button_color: '',
          form_layout_color: 'Teal',
          progressbar_wizard_mode: false,
          header_font_color: '',
          header_font_size: '31',
          form_shadow: false,
          sectionHeader_color: '#edf2f6',
          border_radius: '12',
          progressbar_hide: false,
          header_description_font_size: 16,
          border_width: false,
          submit_btn_align: 'right',
          form_background: '',
          floating_label: false,
          bottom_bar: true,
          sectionHeaderFont_color: '#607b89',
          progressbar_color: '',
          playground_padding_top: 64,
          header_separator_visibility: false,
          playground_margin: 0,
        },
        section_header_align: 'left',
        form_header_align: 'center',
        redirect_url: '',
      },
      sections: [
        {
          config: {
            default_btn: true,
          },
          type: '',
          visiblity: true,
          id: 'sec_nar4tkTleyHn2hTy4xHZ',
          heading_visibility: true,
          heading: 'General Information ',
          rows: [
            {
              fields: [
                {
                  label: 'District',
                  name: 'uiaoaEA43FPa8T3nIw8b',
                  field_type: 'FormInput',
                  bottom_bar: false,
                  rounded: false,
                  placeholder_required: false,
                  floating_label: false,
                  required: true,
                  id: 'nl1ObMs5ZpyLWC1fhxqC',
                  read_only: false,
                  visibility: true,
                },
              ],
              id: 'row_gjP4FP7SFzGYIr7ej5rd',
              config: '',
            },
            {
              config: '',
              id: 'row_3vKc3DiYm9cIEAjrdNRR',
              fields: [
                {
                  placeholder_required: false,
                  help_block_text: '',
                  help_block: false,
                  read_only: false,
                  field_type: 'FormInput',
                  required: true,
                  name: 'GyqYBs814nG3rt3Xx4yZ',
                  label: 'Name of the Cluster',
                  id: 'nqDl2ueKwAHeRYHbfnLE',
                },
              ],
            },
            {
              id: 'row_LuSU859NjqjzcgkwDW0p',
              fields: [
                {
                  required: false,
                  read_only: false,
                  placeholder_required: false,
                  label: 'Gram Panchayat',
                  field_type: 'FormInput',
                  help_block: true,
                  name: 'kzS3owsMAcH3jqKV5VHM',
                  help_block_text: '',
                  id: 'UP00hal8I0jMOUYZLhtJ',
                },
              ],
              config: '',
            },
            {
              config: '',
              fields: [
                {
                  read_only: false,
                  field_type: 'FormInput',
                  help_block: false,
                  required: false,
                  id: 'iSvs8RxdUK4fTvI1hs0U',
                  placeholder_required: false,
                  name: 'SG3d0urUu8OtH7Nwg0DX',
                  label: 'Name of the habitation',
                  help_block_text: '',
                },
              ],
              id: 'row_1prN6H9b7cvvTwnxczeo',
            },
          ],
          name: '',
          hidden: false,
        },
        {
          id: 'sec_izRfmBLStLreGgs6RUMs',
          name: '',
          type: '',
          rows: [
            {
              config: '',
              id: 'row_Rm19i1I4TxcTwOkflHMc',
              fields: [
                {
                  help_block_text: '',
                  id: 'XkEHiEQoirxvwYb6gMZz',
                  field_type: 'FormInput',
                  required: true,
                  help_block: false,
                  placeholder_required: false,
                  read_only: false,
                  name: 'WVhffTTBAssqutjaB3pV',
                  label: '1. Name ',
                },
              ],
            },
            {
              fields: [
                {
                  required: true,
                  read_only: false,
                  label: '2. Gender',
                  color: '#037f7f',
                  options: ['Male', 'Female'],
                  field_type: 'FormRadioGroup',
                  pulse: true,
                  id: 'KBDTLyCI42fEnUmAqXhK',
                },
              ],
              config: '',
              id: 'row_wC6IxY3dTUiuOCmqdpzm',
            },
            {
              config: '',
              fields: [
                {
                  required: true,
                  placeholder: 'Number Input',
                  id: '9ISITnroYSD4INDzgaQk',
                  read_only: false,
                  placeholder_required: false,
                  help_block_text: '',
                  label: '3. Age :',
                  minValue: 0,
                  help_block: false,
                  field_type: 'FormNumber',
                },
              ],
              id: 'row_OntSYYBgpZSgbCWRpA6V',
            },
            {
              fields: [
                {
                  label: '4. Caste',
                  field_type: 'FormRadioGroup',
                  options: ['FC', 'OBC', 'SC/ST'],
                  pulse: true,
                  required: false,
                  id: 'ivKykxQ3D2b1DpfTK6md',
                  color: '#037f7f',
                  read_only: false,
                },
              ],
              id: 'row_uBN9wQp8OK9c9lPpiAOm',
              config: '',
            },
            {
              id: 'row_fATKL2A1rO8zs1O4hvvI',
              fields: [
                {
                  options: ['Hindu', 'Muslim', 'Christian ', 'Others'],
                  floating_label: false,
                  required: false,
                  name: 'Dropdown',
                  label: 'Religion',
                  bottom_bar: false,
                  read_only: false,
                  placeholder: 'Select Options',
                  field_type: 'FormDropdown',
                  id: 'K2zTwn2QaveHIlAOcbq0',
                  rounded: false,
                },
              ],
              config: '',
            },
            {
              fields: [
                {
                  placeholder_required: false,
                  help_block_text: '',
                  name: 'KBrQcYVcfquwwCNSgiyK',
                  id: 'K1tgbAOjaN3QlNJVaZzr',
                  help_block: false,
                  read_only: false,
                  required: false,
                  label: 'If other (Religion) ',
                  field_type: 'FormInput',
                },
              ],
              id: 'row_SYGpbNNNzW5oeQDKdpdl',
              config: '',
            },
            {
              config: '',
              fields: [
                {
                  id: 'LT4c9kAt2ue5aGeQzYc9',
                  rounded: false,
                  floating_label: false,
                  required: false,
                  bottom_bar: false,
                  name: 'Dropdown',
                  read_only: false,
                  field_type: 'FormDropdown',
                  placeholder: 'Select Options',
                  options: ['(a) Illiterate', '(b) Functional Literate', '(c) Primary level', '(d) Secondary level ', '(e) Higher Secondary level', '(f) College / University level'],
                  label: '6. Educational Qualification',
                },
              ],
              id: 'row_iCH5sBGmMtJE8PvYGZDM',
            },
          ],
          config: {
            default_btn: true,
          },
          heading: '1. Personal Information',
          visiblity: true,
          hidden: false,
        },
        {
          visiblity: true,
          name: '',
          type: '',
          rows: [
            {
              id: 'row_07PhEZJpwpwisEHLr8CL',
              fields: [
                {
                  options: ['a) Farming ', 'b) Animal husbandry', 'c) Agri. Labourer', 'd) Labourer (outside agriculture) ', 'e) Employed in Government ', 'f) Employed in Private Sector ', 'g) Self-employed – Professional  ', 'h) Self – employed – in Informal Sector '],
                  field_type: 'FormDropdown',
                  required: false,
                  read_only: false,
                  bottom_bar: false,
                  id: 'OAviQJ8o93fxR7CApr6V',
                  rounded: false,
                  placeholder: 'Select Options',
                  label: '(A) Source of Livelihood',
                  name: 'Dropdown',
                  floating_label: false,
                },
              ],
              config: '',
            },
            {
              fields: [
                {
                  id: 'eam2lO5DgBRsDacmkP6z',
                  field_type: 'FormSeparator',
                  read_only: true,
                  required: false,
                },
              ],
              config: '',
              id: 'row_LJdSnWUe0DOUVFE8YguD',
            },
            {
              id: 'row_xLvkSOC2yDmJb6Xr94m9',
              config: '',
              fields: [
                {
                  required: false,
                  label: '(a) Are there SHGs in your locality? (Yes/No)',
                  dense: true,
                  name: '6as9TFv1OWhutluGVhme',
                  read_only: false,
                  help_block_text: '',
                  id: 'hqN8KJFCIdhiefRz82RI',
                  field_type: 'FormToggle',
                  help_block: false,
                },
              ],
            },
            {
              id: 'row_mBQ3Zmxjdicyd0QH4APw',
              fields: [
                {
                  read_only: false,
                  id: 'ONnENbTnB4AgUSPHtXZ5',
                  help_block_text: '',
                  field_type: 'FormToggle',
                  help_block: false,
                  required: false,
                  name: 'XOi2n28SnnerSBcMdJEt',
                  dense: true,
                  label: '(b) Does anyone from your family a member of SHGs? (Yes/No)',
                },
              ],
              config: '',
            },
            {
              config: '',
              fields: [
                {
                  help_block_text: '',
                  placeholder_required: true,
                  required: false,
                  field_type: 'FormInput',
                  label: '(c) If yes, since when (for how many years)? ',
                  name: 'LMVFQshv4pIxQwwNJClT',
                  help_block: false,
                  id: 'jX3YQKIJlnkUWzUJSwUP',
                  placeholder: '',
                  read_only: false,
                },
              ],
              id: 'row_aBhxcZaikJNaY6B2Az9o',
            },
            {
              config: '',
              fields: [
                {
                  help_block_text: '',
                  required: false,
                  id: 'CgeOYnmNguAFAFjwq6Lo',
                  field_type: 'FormToggle',
                  help_block: false,
                  label: '(d) Loan Availed? (Yes / No)',
                  dense: true,
                  name: 'HKc5Z7wYSW2wMxcUZRxr',
                  read_only: false,
                },
              ],
              id: 'row_C54ynEzD13FsckzfmxaB',
            },
            {
              fields: [
                {
                  id: 'lSSS8O7SnZFBn5bUwC0f',
                  options: ['(i) For ceremonies ', '(ii) for basic necessities ', '(iii) for Economic activity', '(iv) others (Specify)'],
                  label: '(e) If yes, Nature of loan? ',
                  rounded: false,
                  name: 'Dropdown',
                  bottom_bar: false,
                  floating_label: false,
                  field_type: 'FormDropdown',
                  placeholder: 'Select Options',
                  read_only: false,
                  required: false,
                },
              ],
              id: 'row_AziS2tJBuqsSkDyiXg1C',
              config: '',
            },
            {
              fields: [
                {
                  label: 'If Other (Specify)',
                  help_block: false,
                  field_type: 'FormInput',
                  placeholder_required: false,
                  id: 'cuYF6UsVeqzgAUgmRSlO',
                  read_only: false,
                  required: false,
                  name: 'FANVVOze4oprhErdcG3V',
                  help_block_text: '',
                },
              ],
              id: 'row_d2o0J7jv3n8XpXkI230X',
              config: '',
            },
            {
              config: '',
              id: 'row_zQUpjQWLT5BpEUdss8hD',
              fields: [
                {
                  label: '(f) Size of loan',
                  id: 'cKIcuDNC0f94nDXG5qki',
                  color: '#037f7f',
                  read_only: false,
                  pulse: true,
                  field_type: 'FormRadioGroup',
                  required: false,
                  options: ['less than Rs.2500', 'Less than 5000', 'less than 10,000 ', 'more than Rs.10000/- '],
                },
              ],
            },
            {
              id: 'row_DGCBcNJsn83VoimrXghs',
              config: '',
              fields: [
                {
                  id: '06kOR4OhPVN7PDhIFkEI',
                  color: '#037f7f',
                  field_type: 'FormRadioGroup',
                  pulse: true,
                  options: ['one time', 'two time', 'three time', 'many times'],
                  read_only: false,
                  required: false,
                  label: '(g) How many times? ',
                },
              ],
            },
          ],
          config: {
            default_btn: true,
          },
          heading: 'Section-2',
          id: 'sec_aBDJxImnSijKfeKVlod3',
          hidden: false,
        },
        {
          id: 'sec_0F6xexYwYBgyCOycAwXl',
          hidden: false,
          config: {
            default_btn: true,
          },
          rows: [
            {
              config: '',
              id: 'row_4WuJdnglmvu3vfsxswgh',
              fields: [
                {
                  label: '(a)   Agricultural Land (Y / N)',
                  help_block_text: '',
                  read_only: false,
                  id: 'hHaU0TeDgjoH657HeoWr',
                  help_block: false,
                  required: false,
                  dense: true,
                  name: 'BsXrv10ZlKRvAPO5fyWU',
                  field_type: 'FormToggle',
                },
              ],
            },
            {
              id: 'row_cxmDZijXEmzYY9bhOVn1',
              fields: [
                {
                  field_type: 'FormToggle',
                  help_block_text: '',
                  name: 'OrCj90QMAQZOclylo8Gc',
                  required: false,
                  read_only: false,
                  id: 'nIYkpWFHVPDWuuALtwO3',
                  label: '(b)\tIf yes, Soil Health Card (Y / N) ',
                  dense: true,
                  help_block: false,
                },
              ],
              config: '',
            },
            {
              fields: [
                {
                  required: false,
                  rounded: false,
                  options: ['                 (i) Own house ', '(ii) Rented House ', '(iii) got through PMAY / IAY'],
                  bottom_bar: false,
                  label: '            (c) House ownership ',
                  name: 'Dropdown',
                  field_type: 'FormDropdown',
                  id: 'ofuiUXOSRfPAfAEEOpS8',
                  read_only: false,
                  placeholder: 'Select Options',
                  floating_label: false,
                },
              ],
              id: 'row_xtw2fC2F1XHAKnSgc2Mx',
              config: '',
            },
            {
              id: 'row_FiemRmlWeOOcfha5rbZN',
              fields: [
                {
                  id: 'XSJigx25RWpO5p73EA8K',
                  label: '(d) What is the quality of the house ?',
                  color: '#037f7f',
                  options: [' Kutcha  ', 'Tiled', 'Concrete'],
                  field_type: 'FormRadioGroup',
                  required: false,
                  read_only: false,
                  pulse: true,
                },
              ],
              config: '',
            },
            {
              fields: [
                {
                  pulse: true,
                  color: '#037f7f',
                  required: false,
                  field_type: 'FormRadioGroup',
                  options: ['One KB ', 'One BHK', 'Two BHK', 'Three BHK'],
                  label: '(e) What is the size of the house ?',
                  id: 'C4pvODJfcH9IzmCi0vwR',
                  read_only: false,
                },
              ],
              config: '',
              id: 'row_I3ZiHLruYCXQRbZGZpHl',
            },
            {
              fields: [
                {
                  bottom_bar: false,
                  label: '           (f) What Source of energy does your family use for cooking?',
                  field_type: 'FormDropdown',
                  name: 'Dropdown',
                  options: ['(i) LPG ', '(ii) Biogas ', '(iii) Kerosene ', '(iv) Wood ', '(v) Cow dung ', '(vi) Others'],
                  read_only: false,
                  rounded: false,
                  placeholder: 'Select Options',
                  required: false,
                  id: 'BQNK6V7ae1nFY4NyxPpk',
                  floating_label: false,
                },
              ],
              config: '',
              id: 'row_cp3sf0KLIdZJZbJwUO8M',
            },
            {
              fields: [
                {
                  field_type: 'FormInput',
                  read_only: false,
                  required: false,
                  placeholder_required: false,
                  help_block_text: '',
                  label: 'If other (Specify)',
                  name: 'FDA6IjhAoeR1tHDl5CeG',
                  id: 'uVVjMNEYVX7DWWKkCdto',
                  help_block: false,
                },
              ],
              config: '',
              id: 'row_XbIKmCVFXvV8fS3K7wCf',
            },
          ],
          visiblity: true,
          name: '',
          heading: '3. Asset Ownership by Households:',
          type: '',
        },
        {
          config: {
            default_btn: true,
          },
          heading: '4. Changes in Livelihood after SPMRM (Shyam Prasad Mukherjee Rurban Mission):-',
          id: 'sec_jFHeF1v5Bp31yhI0Qrnz',
          rows: [
            {
              config: '',
              fields: [
                {
                  read_only: false,
                  help_block_text: '',
                  field_type: 'FormToggle',
                  dense: true,
                  label: '(a) Has the mission helped improve the livelihood option of the household? (Yes/No)',
                  name: '7yRI93YLlpuaj2z5aD9z',
                  id: 'Iot322FrQaeydAGRusN5',
                  required: false,
                  help_block: false,
                },
              ],
              id: 'row_XDAfWpFSuOOD41nLoM0s',
            },
            {
              id: 'row_yJU6kIdgXHm6LeOdiOeW',
              fields: [
                {
                  field_type: 'FormTextarea',
                  max_length_required: false,
                  required: false,
                  show_limit: true,
                  max_length: null,
                  column_required: false,
                  label: 'If yes, what support the household has got for livelihood improvement?',
                  placeholder: 'Input Text',
                  auto_resize: true,
                  help_block: false,
                  rows_required: false,
                  help_block_text: '',
                  id: 'jEn6Hmg1d8SSBjEoYlr4',
                  placeholder_required: false,
                  read_only: false,
                  column: null,
                  rows: 3,
                },
              ],
              config: '',
            },
            {
              fields: [
                {
                  id: 'ckEsaDlAElVhZsqroKnZ',
                  field_type: 'FormToggle',
                  name: 'm0wJsPm9xn2SI92gcyz4',
                  help_block_text: '',
                  help_block: false,
                  read_only: false,
                  required: false,
                  dense: true,
                  label: '(i) Underwent skill training (any member of the family) ',
                },
              ],
              config: '',
              id: 'row_sF8WYJ2emtonNRlU81Fz',
            },
            {
              fields: [
                {
                  field_type: 'FormToggle',
                  help_block: false,
                  required: false,
                  dense: true,
                  help_block_text: '',
                  read_only: false,
                  id: '2uvvHrEhd8Ozcka2C8IR',
                  label: '(ii) Got a job / employment after training (any member of the family) ',
                  name: 'MMD3ihRNRqrIcSXQfvwy',
                },
              ],
              config: '',
              id: 'row_k3Y7djNwEmhOrL8jmMFO',
            },
            {
              fields: [
                {
                  field_type: 'FormDropdown',
                  options: ['(a)\tStrongly agree', '(b) Agree ', '(c) No Idea ', '(d) Disagree ', '(e) Strongly Disagree '],
                  rounded: false,
                  name: 'Dropdown',
                  read_only: false,
                  floating_label: false,
                  placeholder: 'Select Options',
                  bottom_bar: false,
                  required: false,
                  id: 'ECK30POlmb4ZT7Plwdxx',
                  label: '(iii) Livelihood enhanced (i.e. existing livelihood made better)   If yes,',
                },
              ],
              id: 'row_8oiPAL3ZxBbrQpBu3EhF',
              config: '',
            },
            {
              id: 'row_fnuNNHObW0LNTwtxSQPU',
              config: '',
              fields: [
                {
                  name: 'Dropdown',
                  options: ['(a)\tStrongly agree', '(b) Agree ', '(c) No Idea ', '(d) Disagree ', '(e) Strongly Disagree '],
                  floating_label: false,
                  placeholder: 'Select Options',
                  label: '(iv) Livelihood diversified (i.e. new /additional livelihood taken up) If yes,',
                  read_only: false,
                  field_type: 'FormDropdown',
                  bottom_bar: false,
                  id: 'njECycaYPhUs1zRQrBvZ',
                  required: false,
                  rounded: false,
                },
              ],
            },
            {
              id: 'row_QVLwBN6PbI3fMbnsrHSu',
              fields: [
                {
                  label: '(v) Got technology enablement (New tools / method of working etc.) If yes,',
                  field_type: 'FormRadioGroup',
                  options: ['Fully Agree ', 'Partly Agree ', 'No, Not at all'],
                  required: false,
                  color: '#037f7f',
                  id: 'lQo2gV2eK7JvG9OUMsfm',
                  read_only: false,
                  pulse: true,
                },
              ],
              config: '',
            },
            {
              id: 'row_4827rzzd80uDGNLmBdmZ',
              config: '',
              fields: [
                {
                  dense: true,
                  required: false,
                  help_block_text: '',
                  label: '(vi) Got credit support (or subsidy linked credit support)',
                  help_block: false,
                  name: 'drQz7RFVMHKWrNqxsCOM',
                  id: 'YrVVrSEi0oG219OMlk10',
                  field_type: 'FormToggle',
                  read_only: false,
                },
              ],
            },
            {
              id: 'row_rmavle7os8oJ9zfDux4n',
              config: '',
              fields: [
                {
                  color: '#037f7f',
                  options: ['Yes to a considerable extent ', 'Yes to some extent ', 'No, not at all'],
                  pulse: true,
                  required: false,
                  label: '(vii) Got marketing assistance , If yes',
                  id: 'KMZk4EUzezwiyWlZO3KC',
                  field_type: 'FormRadioGroup',
                  read_only: false,
                },
              ],
            },
            {
              fields: [
                {
                  read_only: false,
                  required: false,
                  pulse: true,
                  color: '#037f7f',
                  field_type: 'FormRadioGroup',
                  label: '(viii) Helped productivity improvement in farming, If yes ',
                  id: 'WC4w9WSYFOUhPbSdFqGZ',
                  options: ['Yes, to a considerable extent ', 'Yes, to some extent', 'No, not at all'],
                },
              ],
              id: 'row_H7MYqECIeduvsJWEXGG2',
              config: '',
            },
            {
              config: '',
              fields: [
                {
                  required: false,
                  options: ['Yes, to a considerable extent ', 'Yes, to some extent ', 'No, not at all'],
                  label: '(ix) Productivity improvement in livestock, If yes ',
                  field_type: 'FormRadioGroup',
                  color: '#037f7f',
                  read_only: false,
                  pulse: true,
                  id: 'B4Z4LhiP1gPTTVMRBsUI',
                },
              ],
              id: 'row_5Rpu695gUhe2LM7KC3ct',
            },
            {
              fields: [
                {
                  id: 'IpCWznDAuekFIYyeqJu0',
                  pulse: true,
                  field_type: 'FormRadioGroup',
                  options: ['Yes, to a considerable extent ', 'Yes, to some extent', 'No, not at all'],
                  read_only: false,
                  required: false,
                  color: '#037f7f',
                  label: '(x) Got improvement in agri-extension services If yes',
                },
              ],
              id: 'row_BxSneHBaeuvs82aFTk9c',
              config: '',
            },
            {
              config: '',
              fields: [
                {
                  pulse: true,
                  required: false,
                  options: ['Yes, to a considerable extent  ', 'Yes, to some extent', 'No, not at all'],
                  label: '(xi) Migration for work reduced / stopped  If yes ',
                  color: '#037f7f',
                  id: 'euFCayT1RHY2uc1ZlDPB',
                  read_only: false,
                  field_type: 'FormRadioGroup',
                },
              ],
              id: 'row_Mpd7JivuwmrW7mD2swFD',
            },
            {
              config: '',
              fields: [
                {
                  read_only: false,
                  label: '(xii) Income improved after and because of Rurban Mission ',
                  bottom_bar: false,
                  options: ['(a)\tIncome Improved Marginally ', '(b) Income Improved Significantly ', '(c) No, nothing much'],
                  field_type: 'FormDropdown',
                  id: 'Ogj0GhqzDcJymQg74Rph',
                  placeholder: 'Select Options',
                  name: 'Dropdown',
                  required: false,
                  floating_label: false,
                  rounded: false,
                },
              ],
              id: 'row_AoyBY9Z8JpQkePPaV0ln',
            },
            {
              id: 'row_PxbLUHfm1FCGJOqJqr5u',
              fields: [
                {
                  id: 'pzn7Zmnz79Uj1b4eDqRN',
                  field_type: 'FormToggle',
                  required: false,
                  name: 'awJ0hltI86XT7Cw4pC9e',
                  read_only: false,
                  help_block: false,
                  dense: true,
                  label: '(xiii) Women got equal opportunities in livelihoods ',
                  help_block_text: '',
                },
              ],
              config: '',
            },
          ],
          visiblity: true,
          type: '',
          name: '',
          hidden: false,
        },
        {
          id: 'sec_qlkk10cHgPJalWY2m5Fu',
          config: {
            default_btn: true,
          },
          rows: [
            {
              id: 'row_DMLn0rZ4dNYBo5RAXyui',
              fields: [
                {
                  label: 'a) Do you have school going children in your household? (Yes/No)      If yes,',
                  help_block_text: '',
                  required: false,
                  id: 'H12oJsxYhev2OVxaKj6g',
                  help_block: false,
                  field_type: 'FormToggle',
                  name: 'lZQ5VJbFBG0Cr9SIcZgm',
                  read_only: false,
                  dense: true,
                },
              ],
              config: '',
            },
            {
              config: '',
              fields: [
                {
                  name: 'Dropdown',
                  placeholder: 'Select Options',
                  read_only: false,
                  rounded: false,
                  floating_label: false,
                  id: 'Jh6PmYFTVD0WSYmBDzgD',
                  options: ['(i) Primary', '(ii) Secondary \t', '(iii) Senior Secondary'],
                  required: false,
                  label: '(b) Level of Education of school going family member:',
                  bottom_bar: false,
                  field_type: 'FormDropdown',
                },
              ],
              id: 'row_H3F8KRrda9M8PpdzHzGr',
            },
            {
              id: 'row_jtUF00TxOEX1n62M5cGo',
              config: '',
              fields: [
                {
                  required: false,
                  placeholder: 'Select Options',
                  label: '(c) Is it a government school or Private School he/she is being sent to?',
                  id: 'pa4K3PN7zrqgKXsS5Ttt',
                  bottom_bar: false,
                  floating_label: false,
                  rounded: false,
                  read_only: false,
                  field_type: 'FormDropdown',
                  name: 'Dropdown',
                  options: ['(i) Government school   ', ' (ii) Private School  ', '     (iii) Others (specify)'],
                },
              ],
            },
            {
              config: '',
              id: 'row_R3TjkJIXBORtsF6eFVm1',
              fields: [
                {
                  field_type: 'FormInput',
                  placeholder_required: false,
                  name: 'h4koplGkvMldENIzjPKE',
                  required: false,
                  id: 'vj0pew0HUMeyeimyqARI',
                  help_block_text: '',
                  help_block: false,
                  label: 'If other (Specify)',
                  read_only: false,
                },
              ],
            },
            {
              config: '',
              fields: [
                {
                  rounded: false,
                  label: '(d) How far / near is the school in terms of distance from house? ',
                  bottom_bar: false,
                  options: ['    (i) Less than 250 metres ', '(ii) 250 – 500 metres ', '(iii) Less than 1KM ', '(iv) 1-2 KM ', '    (v) More than 2KM '],
                  floating_label: false,
                  required: false,
                  field_type: 'FormDropdown',
                  name: 'Dropdown',
                  read_only: false,
                  id: '1MEG5HB5wJnxmfV4NNKb',
                  placeholder: 'Select Options',
                },
              ],
              id: 'row_w18inA2kEYNspvQQx04N',
            },
            {
              fields: [
                {
                  bottom_bar: false,
                  options: ['(i)\tby walk  ', '(ii) by cycle ', ' (iii) by government bus ', '(iv) by school bus ', '(v) others - specify  '],
                  label: '  (e) What is the mode of transport the boy / girl uses to go to school? ',
                  rounded: false,
                  floating_label: false,
                  read_only: false,
                  id: 'DLkW0ZG2Xw4BomgaMfu7',
                  field_type: 'FormDropdown',
                  required: false,
                  placeholder: 'Select Options',
                  name: 'Dropdown',
                },
              ],
              id: 'row_Pn6BnqeeHeeZ03fpot8B',
              config: '',
            },
            {
              id: 'row_hPZLjaj8sc504ibYnihm',
              fields: [
                {
                  name: '1nHaQsK7duI299cCJFSD',
                  field_type: 'FormInput',
                  label: 'If other (Specify)',
                  help_block: false,
                  read_only: false,
                  placeholder_required: false,
                  id: '8sGvLousheDqyhUkGtZR',
                  required: false,
                  help_block_text: '',
                },
              ],
              config: '',
            },
            {
              config: '',
              id: 'row_hmk4Y8Wqzt6EY9lGbauv',
              fields: [
                {
                  read_only: false,
                  field_type: 'FormRadioGroup',
                  options: ['Yes ', 'To some extent ‘yes’ ', 'No'],
                  color: '#037f7f',
                  required: false,
                  pulse: true,
                  label: '(f) Is there any considerable improvement in the ‘quality of school infrastructure’ in the        government-run school in the last 3 - 4 years (after SPMRM)?',
                  id: 'oHfAs45tx3v0g2GO8pQv',
                },
              ],
            },
            {
              fields: [
                {
                  field_type: 'FormRadioGroup',
                  color: '#037f7f',
                  id: 'RJCyfqMfXQiYjp27yIP1',
                  label: '(g) Is there any considerable improvement in the ‘quality of education’ offered in the           \tgovernment-run school in the last 3-4 years?',
                  options: ['yes', 'o some extent ‘yes’ ', 'No'],
                  pulse: true,
                  read_only: false,
                  required: false,
                },
              ],
              config: '',
              id: 'row_oLTLfORW19ji4wnUOyIx',
            },
            {
              fields: [
                {
                  label: '(h) Which hospital do you generally go for your health-related needs?',
                  placeholder: 'Select Options',
                  bottom_bar: false,
                  read_only: false,
                  floating_label: false,
                  options: ['(i) Government dispensary ', '(ii) Private Hospital ', '(iii) Private clinic ', '(iv) others'],
                  id: 'qdjABvU3gp64ETGz2dlk',
                  rounded: false,
                  name: 'Dropdown',
                  required: false,
                  field_type: 'FormDropdown',
                },
              ],
              id: 'row_bCS8ZuwezSI35ui7SyVB',
              config: '',
            },
            {
              config: '',
              fields: [
                {
                  placeholder_required: false,
                  help_block_text: '',
                  read_only: false,
                  name: 'rSm0BsNBjfYq2pYxpMWc',
                  required: false,
                  help_block: false,
                  field_type: 'FormInput',
                  label: 'If other (Specify)',
                  id: 'ile3VrBBkTrH5XcnHx8T',
                },
              ],
              id: 'row_o2RM2qqv27VYkYgUueYm',
            },
            {
              fields: [
                {
                  required: false,
                  placeholder: 'Select Options',
                  options: ['(i)\tLess than 1 KM ', '(ii) 1 – 2 KM ', '(iii) 2 – 3 KM ', '(iv) 3-4 KM', ' (v) 4 -5 KM ', ' (vi) More than 5 KM'],
                  read_only: false,
                  floating_label: false,
                  id: 'RK0SZT0QyTYtFW3GWJTg',
                  label: '(1)\tIf availing Government Medical service, how far is the nearest dispensary / govt hospital to you?',
                  bottom_bar: false,
                  field_type: 'FormDropdown',
                  name: 'Dropdown',
                  rounded: false,
                },
              ],
              config: '',
              id: 'row_MyI45He6mOFhZ4KZYTIF',
            },
            {
              config: '',
              fields: [
                {
                  floating_label: false,
                  name: 'Dropdown',
                  label: '(2)\tWhat mode of transport do you generally use for going to the hospital?',
                  bottom_bar: false,
                  id: 'pfpLVTBI1ltEuojMvbKF',
                  options: ['(i) By walk ', ' (ii) By Bus ', ' (iii) By Private mode of transport such as bike   ', '  (iv) Others '],
                  placeholder: 'Select Options',
                  read_only: false,
                  field_type: 'FormDropdown',
                  required: false,
                  rounded: false,
                },
              ],
              id: 'row_IsTbcImB873rmsfmT6Ec',
            },
            {
              fields: [
                {
                  field_type: 'FormInput',
                  id: 'tU4KuS8K3VvunmuiGXCm',
                  required: false,
                  label: 'If other (Specify)',
                  name: 'EMnqorjEGPlWbBEJOxwo',
                  help_block: false,
                  placeholder_required: false,
                  read_only: false,
                  help_block_text: '',
                },
              ],
              id: 'row_x84AtLVYMQFPW2V3Bv1z',
              config: '',
            },
            {
              fields: [
                {
                  options: ['(i)\tGood ', ' (ii) No Idea ', ' (iii) Poor '],
                  name: 'Dropdown',
                  bottom_bar: false,
                  floating_label: false,
                  id: 'UqjNIse1TYwrYrh55RJK',
                  label: '(3)\tThe Infastructure at the Dispensary / hospital ',
                  placeholder: 'Select Options',
                  field_type: 'FormDropdown',
                  rounded: false,
                  read_only: false,
                  required: false,
                },
              ],
              id: 'row_8ZZSiYbl9sxmzq1oXLUJ',
              config: '',
            },
            {
              config: '',
              id: 'row_cnrjMOXUX1pJtCkvvDzj',
              fields: [
                {
                  name: 'Dropdown',
                  bottom_bar: false,
                  field_type: 'FormDropdown',
                  required: false,
                  options: ['(i)\tAll  of the time ', '(ii) Most of the time ', ' (iii) Some of the time ', ' (iv) Occasionally ', '(v) Never'],
                  placeholder: 'Select Options',
                  label: '(4)\tDoctor present in the dispensary / hospital ',
                  read_only: false,
                  floating_label: false,
                  id: 'gkutXNL7UzeeqBVoPGDt',
                  rounded: false,
                },
              ],
            },
            {
              config: '',
              fields: [
                {
                  placeholder: 'Select Options',
                  floating_label: false,
                  field_type: 'FormDropdown',
                  bottom_bar: false,
                  read_only: false,
                  id: 'jqdQrLqT4L8HkvBUfyqu',
                  rounded: false,
                  required: false,
                  options: ['(i)\tAll  of the time ', '(ii) Most of the time ', '(iii) Some of the time ', ' (iv) Occasionally', ' (v) Never'],
                  name: 'Dropdown',
                  label: '(5)\tNurses and Pharmacist Present in the Dispensary / hospital ',
                },
              ],
              id: 'row_5eb9Hf7vlxxWPaAOW0dD',
            },
            {
              config: '',
              fields: [
                {
                  floating_label: false,
                  field_type: 'FormDropdown',
                  name: 'Dropdown',
                  label: '(6) Provision of free medicines by the dispensary / hospital ',
                  read_only: false,
                  id: '9exWeMDWDdQXhCZSqFQA',
                  placeholder: 'Select Options',
                  rounded: false,
                  required: false,
                  bottom_bar: false,
                  options: ['(i) Always ', ' (ii) Mostly ', ' (iii) Sometimes ', '(iv) Occasionally ', '(v) Never'],
                },
              ],
              id: 'row_wJc3DgxrPp1uXviMhCfM',
            },
            {
              id: 'row_hzFeTHlqKP6cJRA6wG5Z',
              fields: [
                {
                  pulse: true,
                  id: 'ecG16GCS7A5ww9VUXyMV',
                  color: '#037f7f',
                  read_only: false,
                  required: false,
                  label: '(7) Do you get medical lab test / referral facility ?',
                  field_type: 'FormRadioGroup',
                  options: ['Yes', 'No Idea', 'No'],
                },
              ],
              config: '',
            },
            {
              config: '',
              fields: [
                {
                  pulse: true,
                  color: '#037f7f',
                  read_only: false,
                  required: false,
                  field_type: 'FormRadioGroup',
                  options: ['Yes', 'No Idea', 'No'],
                  id: 'BZxBuIP1YM01dBdhTipu',
                  label: '(8) Availability of Maternity ward',
                },
              ],
              id: 'row_QeVM8GxuTmNjzF2mQdaK',
            },
            {
              fields: [
                {
                  options: ['Yes', 'No Idea', 'Maybe'],
                  field_type: 'FormRadioGroup',
                  label: '(9) Is the Free Ambulance Service available ? (108 Emergency)',
                  pulse: true,
                  read_only: false,
                  id: 'TrYtwmJnyoP1gVjQPssQ',
                  color: '#037f7f',
                  required: false,
                },
              ],
              config: '',
              id: 'row_tzPucvZpEOGKMllZ2Tsy',
            },
            {
              fields: [
                {
                  floating_label: false,
                  label: '(i) What is the source of drinking water for your house?',
                  rounded: false,
                  placeholder: 'Select Options',
                  read_only: false,
                  name: 'Dropdown',
                  bottom_bar: false,
                  required: false,
                  id: '8aiikXO6kZsAVbjpoICu',
                  options: ['(i) Purchase water cans ', '(ii) House Service Connection      ', '        (iii) Collect from Public water tap    \t', '(iv) supplied through a water tanker             ', '  (v) Others, specify'],
                  field_type: 'FormDropdown',
                },
              ],
              id: 'row_3gio2OqBoAfjbTBwYbyo',
              config: '',
            },
            {
              fields: [
                {
                  name: 'zEtHUBgvVOQicx6CCKly',
                  id: 'iJxfU9nHWf05TtkNNBwT',
                  help_block: false,
                  placeholder_required: false,
                  required: false,
                  field_type: 'FormInput',
                  help_block_text: '',
                  read_only: false,
                  label: 'If other (Specify)',
                },
              ],
              id: 'row_kynOmzByNJaXNiq09v7s',
              config: '',
            },
            {
              fields: [
                {
                  rounded: false,
                  read_only: false,
                  label: '(j) If the source is not ‘ house service connection’ what distance you travel to collect water?',
                  name: 'Dropdown',
                  bottom_bar: false,
                  floating_label: false,
                  placeholder: 'Select Options',
                  options: ['(i) Less than 50 meters ', '(ii) Less than 100 meters ', '(iii) Less than 1KM        ', '      (iv) More than 1KM'],
                  required: false,
                  field_type: 'FormDropdown',
                  id: 'PqkkqBonzDXwpXHuKY9r',
                },
              ],
              config: '',
              id: 'row_T2JXdVhJ9Yy4uy5Vu7FT',
            },
            {
              fields: [
                {
                  floating_label: false,
                  required: false,
                  id: 'LEF4PIguKB0boKxp5i0c',
                  read_only: false,
                  rounded: false,
                  label: '(k) What is the frequency of water supply?',
                  bottom_bar: false,
                  placeholder: 'Select Options',
                  field_type: 'FormDropdown',
                  options: ['(i) 24*7 Water Supply?  ', '(ii) A few hours daily ', '(iii) Alternative day supply             ', ' (iv)   Two-three days in a week ', '(v) Once a week'],
                  name: 'Dropdown',
                },
              ],
              config: '',
              id: 'row_sX8KbHCb9FLzJhkWIgwK',
            },
            {
              fields: [
                {
                  id: 'RAwJEyt0pAcLLoSZZ16R',
                  required: false,
                  name: 'Dropdown',
                  bottom_bar: false,
                  read_only: false,
                  placeholder: 'Select Options',
                  rounded: false,
                  options: ['(i) Highly Satisfied ', '(ii) Satisfied ', '(iii) No Idea ', '(iv) Dissatisfied ', '(v) Highly Dissatisfied'],
                  field_type: 'FormDropdown',
                  label: '(l) Are you satisfied with the ‘Quality of water supplied? ',
                  floating_label: false,
                },
              ],
              config: '',
              id: 'row_TjGqHwFyMhKsLrOmxIt4',
            },
            {
              fields: [
                {
                  name: 'Dropdown',
                  bottom_bar: false,
                  field_type: 'FormDropdown',
                  options: ['(i) Highly Satisfied ', ' (ii) Satisfied ', '(iii)  No Idea ', '(iv) Dissatisfied ', '(v) Highly Dissatisfied'],
                  required: false,
                  placeholder: 'Select Options',
                  floating_label: false,
                  rounded: false,
                  id: '0jrgrd5iJ1fZYG4HPpmz',
                  read_only: false,
                  label: '(m) Are you satisfied with the ‘Quality of water? ',
                },
              ],
              config: '',
              id: 'row_fyqDJ0CVB0jfBD6PnFd8',
            },
            {
              config: '',
              id: 'row_FIN6AiJtb3qQ9HTOBhAz',
              fields: [
                {
                  read_only: false,
                  rounded: false,
                  floating_label: false,
                  placeholder: 'Select Options',
                  field_type: 'FormDropdown',
                  bottom_bar: false,
                  id: '93mvlJbs6LxFQmpZN3GR',
                  options: ['(i) Highly Satisfied ', ' (ii) Satisfied ', '(iii)  No Idea ', '(iv) Dissatisfied ', '(v) Highly Dissatisfied'],
                  label: '(n) Are you satisfied with ‘regularity’ of water supply?',
                  name: 'Dropdown',
                  required: false,
                },
              ],
            },
            {
              fields: [
                {
                  field_type: 'FormToggle',
                  read_only: false,
                  required: false,
                  help_block_text: '',
                  label: '(o) Do you have latrine in your house? (yes/no)',
                  help_block: false,
                  id: 'iIPRIF80Xj0DmcdAdweb',
                  name: 'ilOC7lNwcIIidRw4Cu0l',
                  dense: true,
                },
              ],
              id: 'row_LEM34vcYI52D80bzglot',
              config: '',
            },
            {
              id: 'row_lQioOF16Ip62ZtHyGcww',
              fields: [
                {
                  id: '4Fgc8eANDYH5xMKmwJ8A',
                  rounded: false,
                  field_type: 'FormDropdown',
                  options: ['       (i) Septic tank type ', '(ii) Twin-leach pit ', '(iii) Single Pit        ', '(iv) Toilet attached to Sewerage \tsystem ', '(v) Others'],
                  read_only: false,
                  floating_label: false,
                  required: false,
                  bottom_bar: false,
                  placeholder: 'Select Options',
                  name: 'Dropdown',
                  label: '(p) What type of Latrine Is it?',
                },
              ],
              config: '',
            },
            {
              fields: [
                {
                  help_block: false,
                  id: 'VR2JltC1EJGrz71U31fA',
                  field_type: 'FormInput',
                  required: false,
                  help_block_text: '',
                  placeholder_required: false,
                  read_only: false,
                  label: 'If other (Specify)',
                  name: 'lVqtZhJrZjA0e3bNVK3h',
                },
              ],
              id: 'row_0lZfOok5PUcyHMxIFMuJ',
              config: '',
            },
            {
              id: 'row_6hNzrQI6B0EBDxuPLtJq',
              fields: [
                {
                  label: '(q) Was your latrine constructed under SBM-G? (Yes/no)',
                  dense: true,
                  field_type: 'FormToggle',
                  help_block_text: '',
                  read_only: false,
                  required: false,
                  id: 'bwVXeJLQv0OC0SY3SHfE',
                  help_block: false,
                  name: 'Di4ESUkPJMvPE1sJJ4q7',
                },
              ],
              config: '',
            },
            {
              id: 'row_LifaMSICzXpwp3rxelee',
              fields: [
                {
                  name: 'KfwO70nMihFMRFKlZN81',
                  label: '(r) Is water supply facility available to your toilet? (Yes/no)',
                  id: 'SAhzzvj6jNn2RHTQBSZ4',
                  dense: true,
                  read_only: false,
                  help_block_text: '',
                  field_type: 'FormToggle',
                  required: false,
                  help_block: false,
                },
              ],
              config: '',
            },
            {
              id: 'row_WJdImtDMk1FKZNSRV3NA',
              fields: [
                {
                  required: false,
                  help_block_text: '',
                  help_block: false,
                  dense: true,
                  id: '9DOcs1wqIZk2QgjtbCA6',
                  label: '(s) Is the toilet being used? (Yes/No)',
                  name: '2B6wNvXnW517UCE8369E',
                  read_only: false,
                  field_type: 'FormToggle',
                },
              ],
              config: '',
            },
            {
              id: 'row_l3znZzKpnvkLuxIb4s2n',
              fields: [
                {
                  field_type: 'FormToggle',
                  dense: true,
                  help_block: false,
                  id: 'axx4hUbocWLUkp9Kq1br',
                  name: 'ANH6PeXV7U2u8JCRb3nS',
                  read_only: false,
                  required: false,
                  help_block_text: '',
                  label: '(t) Is any of your family members still defecating in the open places? (Yes/No)',
                },
              ],
              config: '',
            },
            {
              id: 'row_STUVR5zuSDkE2iGa4lI1',
              fields: [
                {
                  field_type: 'FormToggle',
                  id: 'yi7A2jotfgzEZqitdrsn',
                  required: false,
                  help_block_text: '',
                  read_only: false,
                  dense: true,
                  help_block: false,
                  label: '(u) Do you segregate waste at your household? (as wet waste, dry waste etc.) (Yes/No)',
                  name: 'bdAQHIKles14rXEnHpnr',
                },
              ],
              config: '',
            },
            {
              id: 'row_UoXhUoUXPDX5JhCfTy3O',
              fields: [
                {
                  name: 'Dropdown',
                  placeholder: 'Select Options',
                  required: false,
                  bottom_bar: false,
                  read_only: false,
                  id: 'RgH4WgVezEaTGKxEk0cs',
                  label: '(v) Where & how do you dispose your household waste?',
                  rounded: false,
                  field_type: 'FormDropdown',
                  options: ['(i) Given to Waste Collection Vehicle ', '(ii) Dumped in common waste bin on road         ', ' (iii) Disposed away from house in common places ', '(iv) Others, Specify'],
                  floating_label: false,
                },
              ],
              config: '',
            },
            {
              config: '',
              id: 'row_5JqcsFvh0SNTPTQbKkZB',
              fields: [
                {
                  help_block: false,
                  label: 'If other (Specify)',
                  required: false,
                  name: '2m69H3eSwBhF37cqBxny',
                  help_block_text: '',
                  read_only: false,
                  field_type: 'FormInput',
                  id: 'Irpmidftbj7x0FZkLSvK',
                  placeholder_required: false,
                },
              ],
            },
            {
              id: 'row_iktThXWOO64i99jXRJcd',
              fields: [
                {
                  label: '(x) How is the wastewater disposed off from your bathroom and kitchen?',
                  options: ['(i) Connected  to a leach-pit ', '(ii) Connected to drainage system     ', '     (iii) Let open in the streets', ' (iv)Others, Specify'],
                  placeholder: 'Select Options',
                  required: false,
                  floating_label: false,
                  field_type: 'FormDropdown',
                  rounded: false,
                  id: 'GaslYEtPTynaSjsHcwvf',
                  bottom_bar: false,
                  name: 'Dropdown',
                  read_only: false,
                },
              ],
              config: '',
            },
            {
              fields: [
                {
                  auto_resize: true,
                  max_length: null,
                  help_block_text: '',
                  field_type: 'FormTextarea',
                  required: false,
                  column: null,
                  placeholder: 'Input Text',
                  column_required: false,
                  label: 'If other (Specify)',
                  rows_required: false,
                  placeholder_required: false,
                  max_length_required: false,
                  rows: '2',
                  help_block: false,
                  read_only: false,
                  id: 'uwzt2boR8hXLEw0VbvB2',
                  show_limit: true,
                },
              ],
              config: '',
              id: 'row_w5jjTCjNbetZ9BQzun01',
            },
          ],
          name: '',
          visiblity: true,
          hidden: false,
          heading: '5. SPMRM on Education:',
          type: '',
        },
        {
          name: '',
          rows: [
            {
              fields: [
                {
                  required: false,
                  help_block_text: '',
                  dense: true,
                  id: '3MvDgLSFRL0RHV6AJxKE',
                  name: 'gw0ktdyGpcE9Jy2aJAv2',
                  label: '(a) Is your village connected by road to get all essential social and economic services such as        education, health care, marketplaces etc? (Yes / No)',
                  read_only: false,
                  field_type: 'FormToggle',
                  help_block: false,
                },
              ],
              config: '',
              id: 'row_53VXwiq8G0512qPU4sys',
            },
            {
              config: '',
              id: 'row_Y3uTzxaaqG3ZNh3m7PFq',
              fields: [
                {
                  field_type: 'FormRadioGroup',
                  pulse: true,
                  options: ['All-weather road (pucca)', 'fair-weather road (Kutcha) '],
                  color: '#037f7f',
                  label: '(b) If yes, type of roads. ',
                  read_only: false,
                  id: 'yhKWtBfH8AXubfjxLKzn',
                  required: false,
                },
              ],
            },
            {
              config: '',
              fields: [
                {
                  required: false,
                  label: '(c) Has any road in your village upgraded from kutcha road to pucca-road in the last three   years? (Y/N) ',
                  help_block_text: '',
                  read_only: false,
                  dense: true,
                  field_type: 'FormToggle',
                  id: 'T7pGEC3WJduPoZK0iOSx',
                  help_block: false,
                  name: 'snDvkbF3vi46tpC0IA9v',
                },
              ],
              id: 'row_0r75d7PFlvtwaB3JuTeZ',
            },
            {
              fields: [
                {
                  field_type: 'FormToggle',
                  required: false,
                  id: 'b9yBFjHS0jvFQsZUaXgE',
                  help_block_text: '',
                  name: '5AqX3jwCcNxI8q6NKPY5',
                  help_block: false,
                  label: '(d) If yes, can you say it has provided improved access to avail all basic services, including      access to market, school, health care etc. (Y / N)  ',
                  dense: true,
                  read_only: false,
                },
              ],
              config: '',
              id: 'row_FYVPKrtQ6ytoFw70FS1D',
            },
            {
              id: 'row_3xBmTPJhRRZEZg2CTmQC',
              config: '',
              fields: [
                {
                  help_block_text: '',
                  field_type: 'FormToggle',
                  dense: true,
                  id: 'jblJEfKND836MksFGab3',
                  name: '896PeyieYBGegzBqRsP4',
                  read_only: false,
                  label: '(e) Do you have public transport buses plying through your village? (Y / N) ',
                  required: false,
                  help_block: false,
                },
              ],
            },
            {
              config: '',
              id: 'row_woQrK1btm593ExNTl2WW',
              fields: [
                {
                  help_block_text: '',
                  dense: true,
                  label: '(f) Is the frequency of public transport sufficient? (Y / N) ',
                  field_type: 'FormToggle',
                  read_only: false,
                  id: 'JKT2FYvDChOcxGoRxfHO',
                  help_block: false,
                  name: 'b8jCctu6j7rZySoV2r2C',
                  required: false,
                },
              ],
            },
          ],
          visiblity: true,
          config: {
            default_btn: true,
          },
          hidden: false,
          id: 'sec_yqDHuNsC21BoPaTGHzlN',
          heading: '6. Mobility / Transportation',
          type: '',
        },
        {
          rows: [
            {
              config: '',
              fields: [
                {
                  help_block_text: '',
                  name: 'ZYlTxaJRN8shB9OZvhqv',
                  label: '(a)\t Presence of Cooperative or Commercial Bank (Y/N)',
                  read_only: false,
                  help_block: false,
                  field_type: 'FormToggle',
                  id: 'mBJ6fZwUFuuv1BzOSo47',
                  required: false,
                  dense: true,
                },
              ],
              id: 'row_RhnttrZSfJHsm3xd8lX6',
            },
            {
              id: 'row_r9FIG2ALqIWLGCZzinB2',
              config: '',
              fields: [
                {
                  id: '0iWjQxsoVq9YshhDTbRA',
                  rounded: false,
                  options: ['(i)\tLess than 100 metres ', ' (ii) Less than 500 metres', ' (iii) Less than 1 KM ', '(iv)  Less than 2 KM', ' (v) More than 2 KM'],
                  required: false,
                  placeholder: 'Select Options',
                  label: '(b)\tHow far is the bank from your house ?',
                  floating_label: false,
                  field_type: 'FormDropdown',
                  bottom_bar: false,
                  name: 'Dropdown',
                  read_only: false,
                },
              ],
            },
            {
              config: '',
              fields: [
                {
                  help_block_text: '',
                  required: false,
                  id: '56RvoUJIo22ch6pZLA6V',
                  read_only: false,
                  label: '(c)\tDo you have a bank account?   Y/N',
                  name: 'MD8HKKBxVYpz1Ng4S0sw',
                  dense: true,
                  help_block: false,
                  field_type: 'FormToggle',
                },
              ],
              id: 'row_mdnBmTeL32ZX9qsnz5W5',
            },
            {
              fields: [
                {
                  field_type: 'FormToggle',
                  required: false,
                  name: 'pVn3i0wKLXzdJ6JDaWT8',
                  read_only: false,
                  label: '(d)\tIs your bank active operational / active? Y/N',
                  dense: true,
                  help_block_text: '',
                  help_block: false,
                  id: 'qUhgjKdFILyZfE4wYHRh',
                },
              ],
              id: 'row_piagjHSMRgxvdtjSaVPA',
              config: '',
            },
            {
              fields: [
                {
                  field_type: 'FormDropdown',
                  read_only: false,
                  name: 'Dropdown',
                  bottom_bar: false,
                  id: 'ADAOC19KqMmeEbJqE4Ss',
                  floating_label: false,
                  options: ['(i)\tSave money ', '(ii) Access Credit ', '(iii) Send or Receive money ', '(iv) others '],
                  required: false,
                  placeholder: 'Select Options',
                  rounded: false,
                  label: '(e)\tIf yes, for what purpose do you use banking services?',
                },
              ],
              id: 'row_AfQELwQzEpoq2WpKE1NB',
              config: '',
            },
            {
              fields: [
                {
                  help_block_text: '',
                  field_type: 'FormInput',
                  required: false,
                  placeholder_required: false,
                  help_block: false,
                  id: 'ahwxGFGK6RDVW1mmu0WV',
                  name: 'pmUmNQvynIsxrGMBirSg',
                  label: 'If other (Specify)',
                  read_only: false,
                },
              ],
              config: '',
              id: 'row_AQEyghoq3DNndsDcR0ug',
            },
          ],
          visiblity: true,
          heading: '7. Financial Inclusion in Habitation/Cluster',
          name: '',
          id: 'sec_L3nSGXNPIIW82QpXmr05',
          type: '',
          hidden: false,
          config: {
            default_btn: true,
          },
        },
        {
          visiblity: true,
          config: {
            default_btn: true,
          },
          hidden: false,
          name: '',
          type: '',
          id: 'sec_bTVNLYxpcWvC6OVIfKW6',
          rows: [
            {
              config: '',
              id: 'row_0Dtxmp9mgo56OadpO7r5',
              fields: [
                {
                  name: 'lBP3rnV8hBGASz6H6Jd9',
                  required: false,
                  help_block_text: '',
                  field_type: 'FormToggle',
                  read_only: false,
                  label: '(a)\tDo you have a telephone connection? Y/N',
                  help_block: false,
                  dense: true,
                  id: 'cRNByP9rg7km0EYoI2hd',
                },
              ],
            },
            {
              config: '',
              fields: [
                {
                  id: 'AZKsZllJ8r1oPnjM5nRk',
                  read_only: false,
                  floating_label: false,
                  placeholder: 'Select Options',
                  bottom_bar: false,
                  options: ['(i) landline phone ', '(ii) simple Mobile handset \t', '(iii) Smart Phone ', ' (iv) Smartphone with net connectivity'],
                  label: 'If yes ',
                  name: 'Dropdown',
                  required: false,
                  field_type: 'FormDropdown',
                  rounded: false,
                },
              ],
              id: 'row_ecA1mh1zNSTIZBZzzWHs',
            },
            {
              id: 'row_SGFtUSmLDOOtVsNI3FQO',
              fields: [
                {
                  field_type: 'FormToggle',
                  name: 'l3G1n30AoLsM4RYyHHh9',
                  dense: true,
                  help_block: false,
                  help_block_text: '',
                  label: '(b)\tDo you use internet? Y/N',
                  id: 'F3Job3oGQf1aP8qULxyl',
                  read_only: false,
                  required: false,
                },
              ],
              config: '',
            },
            {
              config: '',
              fields: [
                {
                  rounded: false,
                  label: '(c)\tFor what purpose you use digital services?',
                  read_only: false,
                  name: 'Dropdown',
                  floating_label: false,
                  field_type: 'FormDropdown',
                  bottom_bar: false,
                  id: '139Xn1qCkOuxepMCQYQ7',
                  options: ['(i)\tUse e-mail ', '(ii)\tRead News ', '(iii)\tLearn agri.extension / on-line courses ', '(iv)\tBanking – financial services ', '(v)\tFor Govt Schemes ', '(vi)\tEntertainment – songs, video, facebook'],
                  placeholder: 'Select Options',
                  required: false,
                },
              ],
              id: 'row_TKDkxHWVtqBK7Wo3EM5f',
            },
            {
              config: '',
              id: 'row_bAz3Pl6RKWBL0L8S3nEo',
              fields: [
                {
                  label: '(d) Do you have any form of life insurance: Y/N?',
                  help_block_text: '',
                  field_type: 'FormToggle',
                  id: 'b82gZgPLQ2jWHuY6aziW',
                  name: '3yx0uJZ7m8Cgzlc1Qc0V',
                  read_only: false,
                  dense: true,
                  required: false,
                  help_block: false,
                },
              ],
            },
            {
              fields: [
                {
                  placeholder: 'Select Options',
                  name: 'Dropdown',
                  bottom_bar: false,
                  id: 'sMpzVbfXAUmjmCra1LZL',
                  required: false,
                  options: ['(i) Personally done ', '  (ii) done under government scheme     ', '(iii) others, if any'],
                  rounded: false,
                  floating_label: false,
                  field_type: 'FormDropdown',
                  read_only: false,
                  label: 'If yes',
                },
              ],
              id: 'row_HPTQXKH0jJP2r8wLdwRR',
              config: '',
            },
            {
              fields: [
                {
                  auto_resize: true,
                  help_block_text: '',
                  placeholder_required: false,
                  column: null,
                  rows: '2',
                  rows_required: false,
                  field_type: 'FormTextarea',
                  show_limit: true,
                  label: 'others',
                  max_length_required: false,
                  help_block: false,
                  column_required: false,
                  id: 'bClWIgNCfT2cvLWMYJ8C',
                  placeholder: 'Input Text',
                  read_only: false,
                  max_length: null,
                  required: false,
                },
              ],
              id: 'row_IBEq4RC20Yq4XaVlW4Ub',
              config: '',
            },
          ],
          heading: '8. Digital Amenities/Facilities',
        },
        {
          visiblity: true,
          id: 'sec_sFBstz3nEdaVqETvsvWO',
          heading: '9. Migration',
          name: '',
          type: '',
          config: {
            default_btn: true,
          },
          hidden: false,
          rows: [
            {
              config: '',
              fields: [
                {
                  label: '(a) Did any member of your family migrate during last 3 years / Y/N',
                  name: 'KWp4ylq80U6qer4cjlBY',
                  help_block_text: '',
                  read_only: false,
                  dense: true,
                  help_block: false,
                  required: false,
                  field_type: 'FormToggle',
                  id: 'ZItrpyqiJa9ppKyb3UdC',
                },
              ],
              id: 'row_lTPfI7SfYiGwqsBNorii',
            },
            {
              id: 'row_okssq8xIAW8NLWcTL9rZ',
              config: '',
              fields: [
                {
                  floating_label: false,
                  field_type: 'FormDropdown',
                  name: 'Dropdown',
                  required: false,
                  placeholder: 'Select Options',
                  read_only: false,
                  id: 'aLz3MpBYGIDruwMQjINN',
                  label: 'If yes, nature of migration:',
                  bottom_bar: false,
                  rounded: false,
                  options: ['(i) To another town/district ', '(ii) To another State within India  ', '(iii) To another country'],
                },
              ],
            },
            {
              config: '',
              fields: [
                {
                  options: ['(i) Distressed due to continued unemployment / under employment ', '(ii) For Education purpose ', '(iii) For better financial gains ', '(iv) Local indebtedness ', '(v) Marriage ', '(vi) Other reasons '],
                  name: 'Dropdown',
                  label: '(c) What was the reason behind the migrate?',
                  bottom_bar: false,
                  floating_label: false,
                  field_type: 'FormDropdown',
                  read_only: false,
                  placeholder: 'Select Options',
                  required: false,
                  rounded: false,
                  id: 'JDIkz1KPHlVpAN6lmXsM',
                },
              ],
              id: 'row_aXBImiqF1L3j0Qfxq0WT',
            },
          ],
        },
        {
          visiblity: true,
          type: '',
          config: {
            default_btn: true,
          },
          hidden: false,
          id: 'sec_Fug55v60JekR9AGLDW9q',
          rows: [
            {
              fields: [
                {
                  help_block_text: '',
                  name: 'SZOSCFCG0rtRH6qcas4K',
                  label: '(a)\tAre you a beneficiary under the housing programme PMAY (Pradhan Mantri Awas Yojna)? Y/N',
                  id: '9FyZcVECLIsjUXMlrTzK',
                  read_only: false,
                  dense: true,
                  required: false,
                  help_block: false,
                  field_type: 'FormToggle',
                },
              ],
              config: '',
              id: 'row_5SamWh90TFp0FHjbfVgr',
            },
          ],
          heading: '10. Rural Housing',
          name: '',
        },
        {
          name: '',
          hidden: false,
          rows: [
            {
              config: '',
              id: 'row_B9kac4POGOFQsV4jcrzD',
              fields: [
                {
                  read_only: false,
                  label: '(a)\tIs this place, a tourists’ / traders attraction? Y/N',
                  id: 'UblSwXGWcYgBJamQAMRe',
                  dense: true,
                  field_type: 'FormToggle',
                  name: '5MedM7cDYYyYVJZmzS8P',
                  help_block_text: '',
                  required: false,
                  help_block: false,
                },
              ],
            },
            {
              config: '',
              id: 'row_7Bu317ilIZ2Zv93aHoO0',
              fields: [
                {
                  required: false,
                  read_only: false,
                  help_block_text: '',
                  field_type: 'FormInput',
                  label: '(b)\tIf yes, what’s is this place known for, specify ',
                  placeholder_required: false,
                  help_block: false,
                  name: '4X35e0Ls6GISdgJXeW8u',
                  id: 'tMgO3qp4JFbRMLSmV3xI',
                },
              ],
            },
            {
              id: 'row_L7Ry6OURyOirCALAEGtt',
              config: '',
              fields: [
                {
                  read_only: false,
                  help_block_text: '',
                  name: 'fLJGNFLMb5ldzjbN354d',
                  required: false,
                  field_type: 'FormToggle',
                  help_block: false,
                  dense: true,
                  label: '(c)\tHas tourism activity or visit by traders grown in the last three years: Y/N  ',
                  id: 'QzjW5BqexWNHn2bJVjxI',
                },
              ],
            },
            {
              id: 'row_SrnQuikTmP4lbx13c3TS',
              fields: [
                {
                  placeholder_required: false,
                  max_length: null,
                  required: false,
                  placeholder: 'Input Text',
                  show_limit: true,
                  max_length_required: false,
                  field_type: 'FormTextarea',
                  read_only: false,
                  column: null,
                  auto_resize: true,
                  column_required: false,
                  help_block: false,
                  id: 'ag9YupgttL9TkddtGcUM',
                  label: '(d)\tWhat new facilities or services have come up in the last three years, that encourage tourists / traders come here? ',
                  rows_required: false,
                  help_block_text: '',
                  rows: 3,
                },
              ],
              config: '',
            },
            {
              fields: [
                {
                  id: 'qwFElKmfE52wjKmpdCGI',
                  field_type: 'FormToggle',
                  help_block: false,
                  required: false,
                  read_only: false,
                  name: 'TuJtN2QDyOX2FSwvQdCu',
                  help_block_text: '',
                  label: '(e)\tIs there any home-stay facility near-by or in your house? Y/N ',
                  dense: true,
                },
              ],
              id: 'row_XB0URFNnSoJTV0hDUgA5',
              config: '',
            },
            {
              id: 'row_dFXLMKVbIVM3bznQF90X',
              config: '',
              fields: [
                {
                  options: ['Yes, to a considerable extent  ', 'Yes, to some extent ', 'No, nothing much'],
                  required: false,
                  field_type: 'FormRadioGroup',
                  color: '#037f7f',
                  read_only: false,
                  id: 'IuwxaYaanDDaPQaLGW6Z',
                  pulse: true,
                  label: '(f)\tCan we say overall basic facilities / amenities improved in this village, in the last 3 -4 years?',
                },
              ],
            },
            {
              fields: [
                {
                  placeholder: 'Select Options',
                  bottom_bar: false,
                  field_type: 'FormDropdown',
                  read_only: false,
                  options: ['(i) Strongly agree ', '(ii) Agree ', '(iii) No Idea ', '(iv) Disagree ', '(v) Strongly Disagree'],
                  required: false,
                  name: 'Dropdown',
                  rounded: false,
                  id: '7zTo36YU0sgaHhXv92iO',
                  label: '(g)\tThe land value in your cluster improved faster in the last 3 – 4 years?',
                  floating_label: false,
                },
              ],
              id: 'row_SvOD9d77bDyM8za1grGb',
              config: '',
            },
            {
              config: '',
              fields: [
                {
                  id: 'lDyLzTfn7LjAntxtS0xe',
                  field_type: 'FormDropdown',
                  required: false,
                  read_only: false,
                  rounded: false,
                  bottom_bar: false,
                  floating_label: false,
                  options: ['(i) Strongly agree ', '(ii) Agree ', '(iii) No Idea ', '(iv) Disagree ', '(v) Strongly Disagree'],
                  name: 'Dropdown',
                  label: '(h)\tThe number of people / families that migrate in search of work has come down in the last 3 – 4 years',
                  placeholder: 'Select Options',
                },
              ],
              id: 'row_BrMs7Bj0DWqHHTP1S0y5',
            },
          ],
          id: 'sec_RiFtxsqC6rvGMqDkZZUq',
          heading: '11. Tourism',
          visiblity: true,
          type: '',
          config: {
            default_btn: true,
          },
        },
        {
          name: '',
          heading_visibility: false,
          config: {
            default_btn: true,
          },
          type: 'ThankYou',
          rows: [
            {
              fields: [
                {
                  read_only: true,
                  msg: 'Survey successfully Submitted',
                  field_type: 'FormTextBox',
                  id: '5xYlgqC6ERaC6BisEV8D',
                  sub_msg: 'Thanks for your valuable time !!',
                },
              ],
              id: 'row_um4U3as6s5u7VgTel0d1',
              config: '',
              visiblity: true,
              type: '',
            },
            {
              id: 'row_4zmJljRCU31QbEXtdWtF',
              fields: [
                {
                  id: 'oBa7Jf2aMPDQd7Mv9Dx8',
                  deepShadow: false,
                  deepShadowHover: false,
                  label: 'Submit Another Response',
                  background_color: '#638597',
                  outlined: false,
                  rounded: true,
                  font_color: '',
                  btn_margin_top: '12',
                  hidden: false,
                  btn_width: '300',
                  push: false,
                  required: false,
                  raised: false,
                  name: 'multiple_res_btn',
                  read_only: true,
                  field_type: 'FormButton',
                  align: 'center',
                  button_type: 'resubmit',
                },
              ],
              config: '',
              visiblity: true,
              type: '',
            },
          ],
          hidden: false,
          id: 'sec_OzBGn3rRKT1GOhDzS2gB',
        },
      ],
    },
  },
};

export { defaultFormData };
