import Vue from 'vue';
import VueRouter from 'vue-router';
import { loginIdList } from '@/utils/index';

Vue.use(VueRouter);

const requireAuth = (to, from, next) => {
  let userRaw = localStorage.getItem('user');
  if (userRaw) {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user && user.uid && loginIdList.indexOf(user.uid) !== -1) {
      return next();
    }
  }
  next({ name: 'Login' });
};

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../pages/Login.vue'),
  },
  {
    path: '/download',
    name: 'Download',
    component: () => import(/* webpackChunkName: "download" */ '../pages/Download.vue'),
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import(/* webpackChunkName: "login" */ '../pages/Home.vue'),
    beforeEnter: requireAuth,
  },
  {
    path: '/dashboard',
    name: 'DashboardLayout',
    component: () => import('../DashboardLayout.vue'),
    beforeEnter: requireAuth,

    children: [
      {
        path: '/survey',
        name: 'SurveyForm',
        component: () => import('../pages/SurveyForm.vue'),
        beforeEnter: requireAuth,
      },
      {
        path: '/survey/photos',
        name: 'PhotoGallery',
        component: () => import('../components/PhotoGallery.vue'),
      },
      {
        path: '/responses',
        name: 'Responses',
        component: () => import('../pages/Responses.vue'),
        beforeEnter: requireAuth,
      },
    ],
  },

  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
