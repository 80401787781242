<template>
  <div>
    <header class="navbar">
      <!-- ----LOGO & TITLE-------- -->
      <div class="logo-title" @click="navigatetoHome">
        <div>
          <img src="@main/assets/form-studio-only-logo.svg" alt="" class="brand-logo" />
        </div>
        <div style="margin-left: 16px; font-size: 20px ">RURBAN SURVEY</div>
      </div>
      <!-- -------------- -->
      <a href="#" class="toggle-button">
        <span class="bar"></span>
        <span class="bar"></span>
        <span class="bar"></span>
      </a>
      <div class="navbar-links">
        <ul>
          <li class="nav-item" v-if="$route.name !== 'Login'" v-ripple @click="navigatetoHome">
            <i class="light-icon-home"></i>
          </li>
            <li class="nav-item" v-if=" $route.name !== 'Login'" v-ripple @click="logout">
            <i class="light-icon-power"></i>
          </li>
          <li class="nav-item" v-if="$route.name === 'Dashboard'">
            <img src="@main/assets/account.svg" class="account-icon" />
            <!-- <img :src="user.photo_url" v-if="user.photo_url" class="account-icon" /> -->

            <!-- <Dropdown :userName="user.name" /> -->
          </li>
        </ul>
      </div>
    </header>
  </div>
</template>

<script>
// import Dropdown from './Dropdown.vue';

export default {
  name: 'Navbar',
  data() {
    return {};
  },

  methods: {
    navigatetoHome() {
      // if (this.$route.name === 'SurveyForm') {
      //  if (JSON.stringify(this.schema) !== JSON.stringify(this.$store.state.builder.schema)) {
      // if (!window.confirm('You have unsaved changes. Leave without saving?')) {
      // return;
      // }
      // }
      // }
      this.$router.push({ name: 'Home' });
    },

    checkUnsaved_changes() {
      // if (this.unsavedChanges) {
      //   alert("You have unsaved changes");
      this.$router.push({
        name: 'form-settings',
        query: { id: this.$route.query.id },
      });
      // }
    },
    logout(){
      delete window.localStorage["user"]
      this.$router.replace({name:'Login'});
    },

    editProjectName() {
      //
    },
  },
};
</script>

<style lang="scss">
/* Navbar */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  background-color: #203d4a;
  color: #ffffff;
  padding: 6px 24px;
height: 68px;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 100;
}

/* ----------- */
.logo-title {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.brand-title {
  font-size: 24px;
  padding: 16px;
  cursor: pointer;
  line-height: 24px;
  text-transform: uppercase;
}

.project-title {
  font-size: 24px;
  padding: 16px;
  line-height: 24px;
  i {
    cursor: pointer;
  }
}

.project-name_input {
  font-size: 20px;
  padding-right: 16px;
  max-width: 250px;
  color: white;
}
.transparent-input {
  border: none;
  outline: none;
  background: transparent;
  &::placeholder {
    color: #ffffff;
    opacity: 0.4;
  }
}
.brand-logo {
  width: 34px;
  cursor: pointer;
}

.navbar-links ul {
  display: flex;
  height: 100%;
}

.navbar-links li {
  list-style-type: none;
}

.navbar-links .nav-item {
  cursor: pointer;
  padding: 12px;
  height: 100%;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  i {
    font-size: 32px;
  }
  &:hover,
  &:active {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.account-icon {
  height: 28px;
  width: 28px;
  margin-right: 12px;
  border-radius: 50%;
}

/* TODO: Refactor for mobile responsive============== */
// @media (max-width: 600px) {
//   .toggle-button {
//     display: flex;
//   }
//   .navbar-links {
//     display: none;
//     width: 100%;
//   }

//   .navbar {
//     flex-direction: column;
//     align-items: flex-start;
//   }
//   .navbar-links ul {
//     width: 100%;
//     flex-direction: column;
//   }

//   .navbar-links li {
//     text-align: center;
//   }

//   .navbar-links li a {
//     padding: 0.5rem 1rem;
//   }

//   .navbar-links.active {
//     display: flex;
//   }
// }

/*----------------  */
/* ----sidebar btn---- */
label #sidebar_btn {
  z-index: 1;
  color: #fff;
  /* position: fixed; */
  cursor: pointer;
  /* left: 300px; */
  font-size: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#check:checked ~ .sidebar {
  left: -190px;
}
.toggle-button {
  position: absolute;
  top: 0.75rem;
  right: 1rem;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
}

.toggle-button .bar {
  height: 3px;
  width: 100%;
  background-color: white;
  border-radius: 10px;
}
</style>
